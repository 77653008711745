import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { login as apiLogin } from '../../services/API/AuthServices'
import styles from '../../styles/LoginPage.module.css'

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!username || !password) {
      setError('Both fields are required!')
      return
    }
    try {
      const response = await apiLogin(username, password)
      if (response.data.success) {
        login(response.data)
        console.log('Logged in successfully')
        navigate('/my-profile')
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <div className={styles.loginContainer}>
      <h1>Log in</h1>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={styles.inputContainer}>
          <input
            type="text"
            value={username}
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className={styles.inputContainer}>
          <input
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Log in</button>
      </form>
    </div>
  )
}
export default LoginPage
