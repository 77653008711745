import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styles from '../../styles/ChatWindow.module.css'
import {
  getChatMessages,
  updateChatMessages,
  createNewChat,
  sendPromptToChatbot,
} from '../../services/API/ChatServices'
import ErrorCard from '../ErrorCard'
import { getChatbotDataFromSession } from '../../utils/SessionStorageUtil'

const ChatWindow = () => {
  console.log('Chat window renders')
  // useParams lets us grab info from the webpage's address, like IDs.
  let { chatBotId, conversationId } = useParams()

  //Retrieve from the outlet context
  const { setChatMessagesSaved, chatMessagesSaved } = useOutletContext()

  // navigate is like a GPS for our website; it helps us move around.
  const navigate = useNavigate()

  // useState is like a personal notepad. It keeps track of info while using the chat.
  const [chatMessages, setChatMessages] = useState([]) // user's chat messages associated to a particular conversation and chatbot
  const [isLoading, setIsLoading] = useState(true) // while the user' chat messages are being fetched loading is set to true
  const [message, setMessage] = useState('') // it is user's prompt from the input field and it is cleared once there was a successful response to sent the promt to chatbot. In the event that the resuest failed, we still keep the message to allow user to retry sending the prompot again without asking him to type the prompt again
  const [textAreaValue, setTextAreaValue] = useState('') // t is also user's prompt from the input field which is set as a value of the input field and this one is cleared once the user has sent the prompt so the input field shows the placeholder again
  const [information, setInformation] = useState('') // information of the response -> the api call was successfull but perhaps no data was found
  const [errorRetrievingMessages, setErrorRetrievingMessages] = useState(false) // string containing the error message when retrieving user's messages fails at any point
  const [error, setError] = useState('') // string containing the error message when sending a prompt to chatbot
  const [previousChatMessagesSent, setPreviousChatMessagesSent] =
    useState(false) // indicator if new messages added to the conversion have been saved or not yet
  const [chatbotData, setChatbotData] = useState({}) //the data about chatbot being the receiver
  const [isChatbotTyping, setIsChatbotTyping] = useState(false) // indicator if chatbot is 'typing' meaning that the response still not come
  const [sendBtnActive, setSendBtnActive] = useState(false) // if there is no prompt in the input field the send button should be disabled
  const [saveButtonActive, setSaveButtonActive] = useState(false) // the save button should be only active if there is at least one new message in the conversation which was not saved yet
  const [errorSavingChatMessages, setErrorSavingChatMessages] = useState(false) // indicator if there was an error saving the chat messages
  //const [chatMessagesSaved, setChatMessagesSaved] = useState(true) // indicator if messages have been saved which serves different purposes than the error above -> expecially when switching between conversations

  // Another robot that fetches messages for our chat when we get back to historical chat -> it fetches data only if the conversation id is present or if the conversation is changes
  useEffect(() => {
    //RESET THE STATE
    setIsLoading(true)
    setMessage('')
    setTextAreaValue('')
    setErrorRetrievingMessages(false)
    setError('')
    setPreviousChatMessagesSent(false)
    setChatbotData({})
    setIsChatbotTyping(false)
    setSendBtnActive(false)
    setSaveButtonActive(false)
    setErrorSavingChatMessages(false)
    setChatMessagesSaved(true) // This state needs to be updated here as the messages coming from db are already saved. Thus, to not confuse another robot which is responsible for displaying alert in the browser about unsaved changes here we change the state to true

    const fetchChatMessages = async () => {
      console.log('I should run in the use effect of chat win')
      try {
        console.log('I should run in the use effect of chat win - try')
        const response = await getChatMessages(chatBotId, conversationId)
        if (response.success) {
          console.log('ChatMessages retrieved are: ', response.payload.messages)
          setChatMessages(JSON.parse(response.payload.messages))
        } else if (
          !response.success &&
          response.message === 'No messages found'
        ) {
          setInformation(response.message)
        }
      } catch (error) {
        setErrorRetrievingMessages(error)
      }
      setIsLoading(false)
    }

    if (conversationId) {
      setIsLoading(true)

      fetchChatMessages(chatBotId, conversationId)
    } else {
      // If there is no conversationId, reset the conversation state
      console.log('No conversationId, resetting conversation')
      setChatMessages([
        { sender: 'chatbot', message: 'Hello! How can I assist you today?' },
      ])
    }
  }, [conversationId, chatBotId])

  //This robot checks if we have chatbot data saved (Loads chatbot data from session storage when the component mounts) and if not, it takes us to the dashboard.
  useEffect(() => {
    const storedChatbotData = getChatbotDataFromSession()
    if (storedChatbotData && storedChatbotData.chatbotID) {
      setChatbotData(storedChatbotData)
    } else {
      // Redirect to dashboard if chatbot data is not available
      navigate('/chatbot-dashboard')
    }
  }, [navigate])

  // This robot warns us if we're about to leave the page but haven't saved our messages.
  useEffect(() => {
    console.log('State of chat messages: ', chatMessagesSaved)
    const handleBeforeUnload = (e) => {
      // Check if there's any unsaved messages
      if (!chatMessagesSaved) {
        // Prevent the default unload behavior and show a warning message
        e.preventDefault()
        e.returnValue =
          'You have unsent messages. Are you sure you want to leave?'
      }
    }

    // Add event listener
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Remove event listener on cleanup
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [chatMessagesSaved]) // Depend on the 'chatMessagesSaved' state

  // handlePromptChange updates what we're typing into the chat.
  const handlePromptChange = (event) => {
    setMessage(event.target.value)
    setTextAreaValue(event.target.value)
    setSendBtnActive(event.target.value.length > 0 ? true : false)
  }

  // buildRequestBody prepares our message to be sent to the chatbot.
  const buildRequestBody = (messages, messageToBeSent, isRetry = false) => {
    console.log('Inbuild request - messages are: ', messages)
    if (messages.length > 0) {
      if (isRetry) {
        console.log('IT IS RETRY IN BUILD REQUEST')
        let clonedMessages = JSON.parse(JSON.stringify(messages))
        clonedMessages.pop()
        const history = clonedMessages.map((message) => ({
          type: message.sender === 'chatbot' ? 'apiMessage' : 'userMessage',
          message: message.message,
        }))
        console.log('History is: ', history)
        return {
          question: messageToBeSent,
          history: history,
        }
      } else {
        console.log('IT IS NOT RETRY IN BUILD REQUEST')
        const history = messages.map((message) => ({
          type: message.sender === 'chatbot' ? 'apiMessage' : 'userMessage',
          message: message.message,
        }))
        return {
          question: messageToBeSent,
          history: history,
        }
      }
    }
  }

  //IF IT IS ONE LINE of RETURN STATETMENT THEN YOU CAN SKIP RETURN KEYWORD AND THEN USE PARENTHESIS () in arrow funtion

  // handlePromptSend is like pressing the "send" button in a chat.
  const handlePromptSend = async (isRetry = false) => {
    setTextAreaValue('')
    setSendBtnActive(false)
    console.log('isRetry= ', isRetry)
    if (message !== '' && message !== null) {
      console.log('Message is not null and not empty')

      console.log('Chat messages: ', chatMessages)

      if (!isRetry) {
        console.log('It is not retry')
        // Add user's message only if it's not a retry attempt
        const userMessage = { sender: 'user', message: message }
        setChatMessages((prevMessages) => [...prevMessages, userMessage])
        setSaveButtonActive(true)

        console.log('Chat messages in if when it false: ', chatMessages)
      }

      console.log('Chat messages after if: ', chatMessages)

      setIsChatbotTyping(true)

      // Build request body based on conversation context
      let request =
        conversationId && !previousChatMessagesSent
          ? buildRequestBody(chatMessages, message, isRetry)
          : { question: message }

      console.log('The request is: ', request)

      try {
        const response = await sendPromptToChatbot(
          request,
          chatbotData.chatbotURL,
        )

        if (response) {
          console.log('In the response from external API: ', response)
          const apiMessage = { sender: 'chatbot', message: response }
          setChatMessages((prevMessages) => [...prevMessages, apiMessage])
          setError('')
          setPreviousChatMessagesSent(
            conversationId && !previousChatMessagesSent ? true : false,
          )
          setChatMessagesSaved(false)
        } else {
          console.log('There was an error while sending your message.', error)
          setError(`There was an error while sending your message. ${error}`)
        }
      } catch (error) {
        console.error('There was an error while sending your message.', error)
        setError(`There was an error while sending your message. ${error}`)
      } finally {
        setIsChatbotTyping(false)
        setSendBtnActive(false)
      }
    }
  }

  // handleRetrySendMessage lets us try sending a message again if something went wrong.
  const handleRetrySendMessage = () => {
    console.log('RETRYING')
    handlePromptSend(true)
  }

  // handleSaveConversation saves our chat so we can look at it later.
  const handleSaveConversation = async () => {
    let response
    const messages = JSON.stringify(chatMessages)
    const messageObject = { messages: messages }
    console.log('Messages: ', messages)
    try {
      if (conversationId) {
        response = await updateChatMessages(
          chatBotId,
          conversationId,
          messageObject,
        )
      } else {
        const title = chatMessages
          .find((message) => message.sender === 'user')
          ?.message.split(' ')
          .slice(0, 4)
          .join(' ')
          .concat('...')
        response = await createNewChat(chatBotId, title, messageObject)
      }

      // Check response
      if (response?.status === 200) {
        const successMessage = conversationId
          ? 'Chat messages updated successfully'
          : 'Chat messages created successfully'
        if (response.data.success) {
          alert(successMessage)
          setChatMessagesSaved(true)
          if (!conversationId) {
            console.log(
              'New conv is is: ',
              response.data.payload?.conversation_id,
            )
            navigate(
              `/chatbot-dashboard/${chatBotId}/${response.data.payload?.conversation_id}`,
            )
          }
        } else {
          setErrorSavingChatMessages(true)
        }
      } else {
        setErrorSavingChatMessages(true)
      }
    } catch (error) {
      setErrorSavingChatMessages(true)
    } finally {
      setSaveButtonActive(false)
    }
  }
  return (
    <div className={styles.chatWindow}>
      {isLoading && conversationId ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={styles.messagesContainer}>
            <>
              {errorRetrievingMessages ? (
                <ErrorCard
                  message="An unexpected error occurred while retrieving the previous chat messages. Please try again later."
                  to={`/chatbot-dashboard/${chatBotId}/${conversationId}`}
                  name="RETRY"
                />
              ) : (
                <>
                  {Array.isArray(chatMessages) &&
                    chatMessages.map((message, index) => (
                      <div
                        key={index}
                        className={
                          message.sender === 'chatbot'
                            ? styles.chatbotMessage
                            : styles.userMessage
                        }
                      >
                        {message.sender === 'chatbot'
                          ? 'Assistant: ' + message.message
                          : 'User: ' + message.message}
                      </div>
                    ))}
                  {isChatbotTyping && (
                    <div className={styles.chatbotMessage}>
                      Assistant:{' '}
                      <span className={styles.dots}>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </span>
                    </div>
                  )}
                  {error && (
                    <button
                      className={styles.retryBtn}
                      onClick={handleRetrySendMessage}
                    >
                      Retry <br /> {error}
                    </button>
                  )}
                  {errorSavingChatMessages && (
                    <ErrorCard
                      message="An unexpected error occurred while trying to save your conversation"
                      name="OK"
                      action="CONFIRM"
                      handleBtnClick={() => setErrorSavingChatMessages(false)}
                    />
                  )}
                </>
              )}
            </>
          </div>
          <div className={styles.footer}>
            <textarea
              className={styles.inputField}
              onChange={handlePromptChange}
              value={textAreaValue}
              placeholder="Type your query here..."
            />
            <button
              className={
                sendBtnActive ? styles.sendButton : styles.sendButtonInactive
              }
              onClick={() => handlePromptSend()}
              disabled={!sendBtnActive}
            >
              SEND
            </button>
            <button
              className={
                saveButtonActive ? styles.saveButton : styles.saveButtonInactive
              }
              onClick={handleSaveConversation}
              disabled={!saveButtonActive}
            >
              SAVE
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default ChatWindow
