// Import the necessary modules from Axios and axios-auth-refresh
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

// Set the base URL and token key from environment variables.
// These are used to configure the API endpoints and the storage key for the access token.
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const tokenKey = process.env.REACT_APP_TOKEN_KEY

// Create an Axios instance for making HTTP requests.
// This instance is pre-configured with a base URL and default headers.
const api = axios.create({
  baseURL: apiBaseUrl, // Base URL for all requests made using this instance
  //withCredentials: true, // This ensures that cookies are included with every request
  headers: {
    'Content-Type': 'application/json', // Sets the content type of the request to JSON
  },
})

// Function to retrieve the access token from local storage.
const getToken = () => localStorage.getItem(tokenKey)

// Add a request interceptor to the Axios instance.
// This interceptor runs before every request made using this instance.
api.interceptors.request.use(
  (config) => {
    // Checks if the 'withToken' flag is set to false in the request config.
    // If so, the request is sent without an authorization token.
    if (config.withToken === false) {
      return config
    }

    // Retrieves the access token and adds it to the request's Authorization header.
    // This is used for endpoints that require authentication.
    const token = getToken()
    console.log('Token is:', token)
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    // In case of an error in the request setup, this promise is rejected
    // with the error, allowing the calling code to catch it.
    return Promise.reject(error)
  },
)

// Define the logic to refresh the access token.
// This function is called automatically when a request fails due to an expired token.
const refreshAuthLogic = async (failedRequest) => {
  const encryptedUserId = localStorage.getItem('rabbit') // Get encryptedUserId from local storage
  console.log('I am trying to refresh token')
  try {
    // Include the encryptedUserId in the headers of the refresh token request
    const response = await axios.post(
      `${apiBaseUrl}/auth/refresh-token`,
      {},
      {
        headers: {
          'X-Encrypted-User': encryptedUserId,
        },
      },
    )

    console.log('New access token: ', response)
    // Updates the access token in local storage with the new one from the response.
    const newAccessToken = response.data.token
    localStorage.setItem(tokenKey, newAccessToken)

    // Updates the failed request with the new access token and retries it.
    failedRequest.response.config.headers[
      'Authorization'
    ] = `Bearer ${newAccessToken}`
  } catch (error) {
    console.log('Error is : ', error)

    // Redirect to the login page
    window.location.href = '/login'
    // If refreshing the token fails, handle it here (e.g., redirecting to login).
  }
}

// Create and set up an interceptor using the axios-auth-refresh library.
// This interceptor specifically listens for requests that fail due to authorization issues (typically 401 Unauthorized responses).
// When such a failure is detected, it automatically calls `refreshAuthLogic`
// to try to refresh the access token. If the refresh is successful, it retries the original request with the new token.
createAuthRefreshInterceptor(api, refreshAuthLogic)

// Export the configured Axios instance for use throughout the application.
export default api
