// ChatbotDashboard.js
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styles from '../../styles/ChatbotDashboard.module.css'
import { getUserChatbots } from '../../services/API/ChatServices'
import { setChatbotDataInSession } from '../../utils/SessionStorageUtil'
import ErrorCard from '../ErrorCard'
import InformationCard from '../InformationCard'
import Frame from '../Frame'

const ChatbotDashboardPage = () => {
  // Images for chatbot and information icon
  const chatbotImage = process.env.PUBLIC_URL + '/images/chatbot.png'
  const infoImage = process.env.PUBLIC_URL + '/images/info.png'
  const chatAssistantPicture =
    process.env.PUBLIC_URL + '/images/IvonneCircle.png'

  // State management
  const [chatbots, setChatbots] = useState([])
  const [error, setError] = useState('')
  const [information, setInformation] = useState('')
  const [showInformation, setShowInformation] = useState(false)

  // Navigation hook for redirecting the user
  const navigate = useNavigate()

  // Function to handle selecting a chatbot
  const handleSelectChatbot = (chatbot) => {
    // Setting chatbot data in session storage
    setChatbotDataInSession({
      chatbotID: chatbot.chatbot_id,
      chatbotURL: chatbot.ChatbotModel.url,
      chatbotName: chatbot.ChatbotModel.title,
      chatbotDescription: chatbot.ChatbotModel.description,
    })
    // Navigating to chatbot interface
    navigate(`/chatbot-dashboard/${chatbot.chatbot_id}`)
  }

  // Fetching user chatbots on component mount
  useEffect(() => {
    const fetchUserChatbots = async () => {
      try {
        const response = await getUserChatbots()
        if (response.success) {
          // Setting chatbots state if fetch is successful
          setChatbots(response.payload)
        } else if (
          !response.success &&
          response.message === 'No chatbots found'
        ) {
          // Handling the case where no chatbots are assigned to the user
          setInformation(
            "You currently don't have any chatbot assistants assigned. To get started, please reach out to the person in charge of chatbot assignments in your organization. If you have any questions or need further assistance, feel free to contact our support team. We're here to help you every step of the way.",
          )
          setShowInformation(true)
        }
      } catch (error) {
        // Handling errors during data fetch
        console.error('Error fetching chatbots:', error)
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchUserChatbots()
  }, [])

  // Function to hide information card
  const handleInformationVisibility = () => {
    setShowInformation(false)
  }

  // Rendering logic based on different states
  return (
    <div>
      <h1 className={styles.pageTitle}>Chatbot Dashboard</h1>
      <hr className={styles.titleLine} />
      {error ? (
        <ErrorCard message={error} action="RELOAD" name="Retry" />
      ) : showInformation ? (
        <InformationCard
          title="Notice"
          information={information}
          toggleInformationVisibility={handleInformationVisibility}
          imagePath={chatbotImage}
        />
      ) : (
        <div className={styles.dashboard}>
          {chatbots.map((bot) => (
            <Frame
              key={bot.chatbot_id}
              onClick={() => handleSelectChatbot(bot)}
              data={{
                title: bot.ChatbotModel.title,
                description: bot.ChatbotModel.description,
                imagePath: chatAssistantPicture,
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ChatbotDashboardPage
