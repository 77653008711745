// ProtectedRoute.js
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const ProtectedRoutes = ({ roles }) => {
  const { user, isAuthorized, isLoading } = useAuth()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthorized()) {
    return <Navigate to="/login" />
  }

  if (roles && !roles.includes(user.role)) {
    return <Navigate to="/my-profile" />
  }

  return <Outlet />
}

export default ProtectedRoutes
