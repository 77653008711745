import styles from '../styles/InformationCard.module.css'
const InformationCard = ({
  title,
  information,
  toggleInformationVisibility,
  imagePath,
}) => {
  return (
    <div className={styles.backdrop}>
      <div className={styles.card}>
        <div
          className={styles.chatbotImage}
          style={{ backgroundImage: `url(${imagePath})` }}
        />
        <h2>{title}</h2>
        <p>{information}</p>
        <button className={styles.button} onClick={toggleInformationVisibility}>
          OK
        </button>
      </div>
    </div>
  )
}

export default InformationCard
