import styles from '../styles/Frame.module.css'

const Frame = ({ onClick, data }) => {
  return (
    <div className={styles.frame} onClick={onClick}>
      <div className={styles.content}>
        <img className={styles.image} alt="Mask group" src={data.imagePath} />
        <div className={styles.textContainer}>
          <span className={styles.name}>Ivonne</span>
          <span className={styles.title}>{data.title}</span>
        </div>
      </div>
      <p className={styles.description}>{data.description}</p>
    </div>
  )
}

export default Frame
