import axios from 'axios'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const login = async (username, password) => {
  try {
    const response = await axios.post(
      `${apiBaseUrl}/auth/login`,
      {},
      {
        auth: {
          username: username,
          password: password,
        },
      },
    )
    console.log('Response is: ', response)
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}
