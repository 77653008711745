import ConversationListPanel from './ConversationListPanel'
import { Outlet } from 'react-router-dom' // useParams removed as it's not used
import styles from '../../styles/ChatInterface.module.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getUserConversations } from '../../services/API/ChatServices'
import { getChatbotDataFromSession } from '../../utils/SessionStorageUtil'
import ErrorCard from '../ErrorCard' // Import path adjusted as needed
import InformationCard from '../InformationCard'

const ChatInterface = () => {
  console.log('ChatInterface renders')
  // Removed the console log to clean up the console output
  const [chatbotData, setChatbotData] = useState({})
  const [conversations, setConversations] = useState([]) // User conversations associated with the chatbot
  const [error, setError] = useState('')
  const [information, setInformation] = useState('')

  const [chatMessagesSaved, setChatMessagesSaved] = useState(true)

  let { chatBotId, conversationId } = useParams()

  const chatbotImage = process.env.PUBLIC_URL + '/images/chatbot.png'
  const chatAssistantPicture =
    process.env.PUBLIC_URL + '/images/IvonneCircle.png'

  // Load chatbot data from session storage when the component mounts
  useEffect(() => {
    const storedChatbotData = getChatbotDataFromSession()
    if (storedChatbotData) {
      setChatbotData(storedChatbotData)
    }
  }, [])

  // Fetch user conversations when chatbotData is updated
  useEffect(() => {
    const fetchUserConversations = async () => {
      try {
        if (chatbotData.chatbotID) {
          const response = await getUserConversations(chatbotData.chatbotID)
          console.log('Response', response)
          if (
            response.success &&
            response.message === 'Chats have been found'
          ) {
            setConversations(response.payload)
          } else {
            console.log('Messages has been not found')
            setInformation(response.message)
          }
        }
      } catch (error) {
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchUserConversations()
  }, [chatbotData.chatbotID, conversationId])

  return (
    <div className={styles.box}>
      {error ? (
        <ErrorCard message={error} to={`/chatbot-dashboard`} name="RETURN" />
      ) : (
        <>
          <ConversationListPanel
            className={styles.firstBox}
            conversationList={conversations}
            setChatMessagesSaved={setChatMessagesSaved}
            chatMessagesSaved={chatMessagesSaved}
          />
          <div className={styles.secondBox}>
            <Outlet context={{ setChatMessagesSaved, chatMessagesSaved }} />
            {/* Nested routes will render here */}
          </div>
          {information !== '' && (
            <InformationCard
              title="NOTICE"
              information="Hey there! It looks like you haven't had a chat yet. No worries, starting a conversation is super easy. Just type your first message to the chat assistant, and you're on your way to an engaging dialogue. Let's get chatting!"
              toggleInformationVisibility={() => setInformation('')}
              imagePath={chatAssistantPicture}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ChatInterface
