import { createContext, useContext, useState, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext()

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isAuthenticated, setisAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY)
    console.log(token)
    if (token && token !== 'undefined' && token !== '') {
      const decodedToken = jwtDecode(token)
      setUser({
        role: decodedToken.role,
        name: decodedToken.name,
        surname: decodedToken.surname,
        email: decodedToken.email,
        username: decodedToken.username,
      })
      setisAuthenticated(true)
    }
    setIsLoading(false)
  }, [])

  const login = (data) => {
    localStorage.setItem('rabbit', data.encryptedUserId) // Store encryptedUserId
    const decodedToken = jwtDecode(data.token)
    localStorage.setItem(TOKEN_KEY, data.token)
    setUser({
      role: decodedToken.role,
      name: decodedToken.name,
      surname: decodedToken.surname,
      email: decodedToken.email,
      username: decodedToken.username,
    })
    setisAuthenticated(true)
  }

  const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
    setUser(null)
    setisAuthenticated(false)
  }

  const isAuthorized = () => {
    return isAuthenticated
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isAuthorized,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
