import { Link } from 'react-router-dom'
import styles from '../../styles/HamburgerMenu.module.css'
import { useAuth } from '../../contexts/AuthContext'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

const HamburgMenu = ({ toggelVisibilityMenu }) => {
  const { user, logout } = useAuth()

  const handleLinkClick = () => {
    toggelVisibilityMenu()
  }

  return (
    <div className={styles.dropdown}>
      <div className={styles.userDetails}>
        <div className={styles.userCircle}>User</div>
        <div className={styles.userInfo}>
          <div className={styles.userName}>Test User</div>
          <div className={styles.userEmail}>testuser@example.com</div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <Link to="/my-profile" onClick={handleLinkClick}>
        My Profile
      </Link>
      <div className={styles.divider}></div>
      {user?.role === 'web_admin' && (
        <>
          <Link to="/company-management" onClick={handleLinkClick}>
            Company Management
          </Link>
          <div className={styles.divider}></div>
          {/* Any other routes for the web admin */}
        </>
      )}
      {user?.role === 'company_admin' && (
        <>
          <Link to="/chatbot-dashboard" onClick={handleLinkClick}>
            Chatbot Dashboard
          </Link>
          <div className={styles.divider}></div>
          <Link to="/user-management" onClick={handleLinkClick}>
            User Mangement
          </Link>
          <div className={styles.divider}></div>
          <Link to="/chat-token-tracker" onClick={handleLinkClick}>
            Chat Token Tracker
          </Link>
          <div className={styles.divider}></div>
        </>
      )}
      {user?.role === 'user' && (
        <>
          <Link to="/chatbot-dashboard" onClick={handleLinkClick}>
            Chatbot Dashboard
          </Link>
          <div className={styles.divider}></div>
        </>
      )}
      <button
        className={styles.logoutButton}
        onClick={() => {
          handleLinkClick()
          logout()
        }}
      >
        <ExitToAppIcon style={{ marginRight: '8px' }} />
        Log out
      </button>
    </div>
  )
}
export default HamburgMenu
