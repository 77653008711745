import styles from '../../styles/ConversationListPanel.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getChatbotDataFromSession } from '../../utils/SessionStorageUtil' // Import utility for session storage

const ConversationListPanel = ({
  className,
  conversationList,
  chatMessagesSaved,
}) => {
  console.log('ConversationListPanel renders')
  const location = useLocation()

  conversationList.map((conv) => console.log('Is is: ', conv.conversation_id))
  console.log('Conversations list panel: ', conversationList)

  // Option to use chatBotId from session storage
  const chatbotData = getChatbotDataFromSession()
  const chatBotId = chatbotData ? chatbotData.chatbotID : null
  const chatbotName = chatbotData ? chatbotData.chatbotName : 'undefined'

  // Check if the URL is exactly for the new chat
  const isNewChatActive =
    location.pathname === `/chatbot-dashboard/${chatBotId}`

  const getLinkClassName = (conversationId) => {
    // Check if the current conversationId is in the URL
    const isActive = location.pathname.endsWith(
      `/${chatBotId}/${conversationId}`,
    )
    return isActive ? `${styles.link} ${styles.activeLink}` : styles.link
  }

  const navigate = useNavigate()

  const handleLinkClick = (link) => {
    if (
      !chatMessagesSaved &&
      !window.confirm('You have unsaved changes. Continue?')
    ) {
      return
    }
    console.log('HERE')
    navigate(link)
  }

  return (
    <div className={`${styles.panel} ${className}`}>
      <div className={styles.containerSmall}>
        <Link
          className={styles.arrowBack}
          to="/chatbot-dashboard"
          onClick={(e) => {
            e.preventDefault()
            handleLinkClick(`/chatbot-dashboard`)
          }}
        >
          <ArrowBackIcon />
        </Link>
        <div className={styles.name}>{chatbotName}</div>
      </div>

      {/* Link to start a new chat */}
      <Link
        className={
          isNewChatActive ? `${styles.link} ${styles.activeLink}` : styles.link
        }
        to={`/chatbot-dashboard/${chatBotId}`}
      >
        New chat
        <EditNoteIcon />
      </Link>
      <hr className={styles.line} />
      {conversationList.map((conv) => (
        <Link
          key={conv.conversation_id}
          onClick={(e) => {
            e.preventDefault()
            handleLinkClick(
              `/chatbot-dashboard/${chatBotId}/${conv.conversation_id}`,
            )
          }}
          className={getLinkClassName(conv.conversation_id)}
        >
          {conv.title}
        </Link>
      ))}
    </div>
  )
}

export default ConversationListPanel
