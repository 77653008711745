import styles from '../../styles/NavBar.module.css'
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import HamburgerMenu from './HamburgerMenu'
import { Link } from 'react-router-dom'

const NavBar = () => {
  const { user, isAuthorized } = useAuth()
  const [hamburgerVisibility, sethamburgerVisibility] = useState(false)

  const handleHamburgerMenuVisibility = () => {
    sethamburgerVisibility(!hamburgerVisibility)
  }
  const getUserInitials = (user) => {
    console.log('USER: ', user)
    if (user && user.name && user.surname) {
      return `${user.name.charAt(0)}${user.surname.charAt(0)}`
    }
    return ''
  }

  return (
    <nav className={styles.nav}>
      <Link to="/" className={styles.siteTitle}>
        <img src="../HA_Logo.png" className={styles.logo} alt="HA logo" />
        <span>AI Platform</span>
      </Link>
      {isAuthorized() ? (
        <div>
          <button
            className={styles.dropBtn}
            onClick={handleHamburgerMenuVisibility}
          >
            {getUserInitials(user)}
          </button>
          {hamburgerVisibility && (
            <HamburgerMenu
              toggelVisibilityMenu={handleHamburgerMenuVisibility}
            />
          )}
        </div>
      ) : (
        <div>
          <Link className={styles.navButton} to="/login">
            Login
          </Link>
        </div>
      )}
    </nav>
  )
}

export default NavBar
