import api from './APIconfig'

// Fetches user chatbots from the server.
// `withToken` is a flag to determine if the request should include an authorization token.
export const getUserChatbots = async (withToken = true) => {
  try {
    // Makes a GET request to '/chat/getUserChatbots' endpoint.
    // The `withToken` flag is passed to include/exclude the authorization token in the request header.
    const response = await api.get('/chat/getUserChatbots', {
      withToken: withToken,
    })
    return response.data // Returns the data from the response.
  } catch (error) {
    console.log(error)
    throw error // Propagates the error for further handling.
  }
}

// Fetches conversation titles and IDs for a given chatbot.
// `chatbotId` is the identifier of the chatbot.
// `withToken` determines if the request should include an authorization token.
export const getUserConversations = async (chatbotId, withToken = true) => {
  try {
    // The chatbot ID is appended as a query parameter in the request URL.
    const response = await api.get(
      `/chat/getChatsTitlesAndIds?chatbotId=${chatbotId}`,
      { withToken: withToken },
    )
    return response.data // Returns the fetched data.
  } catch (error) {
    console.log(error)
    throw error
  }
}

// Fetches chat messages for a specific conversation in a chatbot.
// `chatbotId` and `conversationId` are used to identify the specific conversation.
// `withToken` indicates if the request should carry an authorization token.
export const getChatMessages = async (
  chatbotId,
  conversationId,
  withToken = true,
) => {
  try {
    // Makes a GET request with chatbot and conversation IDs as query parameters.
    const response = await api.get(
      `/chat/getChatMessages?chatbotId=${chatbotId}&conversationId=${conversationId}`,
      { withToken: withToken },
    )
    return response.data // Returns the chat messages.
  } catch (error) {
    console.log(error)
    throw error
  }
}

// Sends a prompt message to a chatbot.
// `data` contains the message content, `url` is the endpoint to send the message, and `withToken` indicates if the token should be included.
export const sendPromptToChatbot = async (data, url, withToken = false) => {
  try {
    // The data is converted to a JSON string before sending.
    const dataJSON = JSON.stringify(data)
    // POST request is made to the specified URL with the data.
    // `withCredentials: false` means this request does not include cookies or other credentials.
    const response = await api.post(url, dataJSON, {
      withToken: withToken,
    })
    return response.data // Returns the response data from the server.
  } catch (error) {
    console.log(error)
    throw error
  }
}

// Creates a new chat in a chatbot.
// `chatbotID` is the identifier of the chatbot, `title` is the title of the new chat, and `chatMessages` contains the initial messages.
export const createNewChat = async (
  chatbotID,
  title,
  chatMessages,
  withToken = true,
) => {
  try {
    console.log('Chat messages: ', chatMessages)

    // POST request to create a new chat. The chatbot ID and title are passed as query parameters.
    const response = await api.post(
      `/chat/createNewChat?chatbotId=${chatbotID}&title=${title}`,
      chatMessages,
      { withToken: withToken },
    )
    console.log(response)
    console.log(response.data)
    return response // Returns the full response object.
  } catch (error) {
    console.log(error)
    throw error
  }
}

// Updates chat messages for a specific conversation in a chatbot.
// `chatbotID` and `conversationID` identify the conversation, while `chatMessages` contains the updated messages.
export const updateChatMessages = async (
  chatbotID,
  conversationID,
  chatMessages,
) => {
  try {
    // POST request to update chat messages with the chatbot and conversation IDs in the query.
    const reponse = await api.post(
      `/chat/updateChatMessages?chatbotId=${chatbotID}&conversationId=${conversationID}`,
      chatMessages,
    )
    return reponse // Returns the response from the server.
  } catch (error) {
    console.log(error)
    throw error
  }
}
